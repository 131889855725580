<template>
  <div class="video">
    <div class="title">
      <span>放松音乐 </span>
    </div>
    <el-card class="condition_style2">
      <div class="flex">
        <div class="add">
          <el-button  type="primary" size="small" @click="add('')">新增音频</el-button>
        </div>
        <el-form :inline="true" class="flex"  :model="searchForm" >
          <el-form-item>
            <el-select v-model="searchForm.typeId" placeholder="请选择所属分类" clearable>
              <el-option v-for="(item, index) in typeList" :key="index" :label="item.typeName" :value="item.typeId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="searchForm.status" placeholder="请选择状态" clearable>
              <el-option v-for="(item, index) in startList" :key="index" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="searchForm.title" placeholder="请输入标题名称" suffix-icon="el-icon-search" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="getList(true)">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="tableData" style="width: 100%" class="table" :height="tableHeight">
        <el-table-column type="index" label="序号" width="60" align="center" fixed>
          <template slot-scope="scope">{{scope.$index+(current-1) * size + 1}}</template>
        </el-table-column>
        <el-table-column prop="title" label="音频标题" min-width="150" align="center"></el-table-column>
        <el-table-column prop="summary" label="音频介绍" min-width="200" align="center" :show-overflow-tooltip="true">
          <template slot-scope="scope" v-if="scope.row.summary">
            <div class='myNote'>{{scope.row.summary}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="province" label="音频缩略图" min-width="150" align="center">
          <template slot-scope="scope" v-if="scope.row.coverUrl">
            <img class="img_120_72" :src="scope.row.coverUrl">
          </template>
        </el-table-column>
        <el-table-column prop="typeName" label="所属分类" min-width="120" align="center"></el-table-column>
        <!-- <el-table-column prop="orderNum" label="编辑排序" min-width="200" align="center"
          :render-header="renderHeader">
          <template slot-scope="scope">
            <el-input v-if="showBtn" v-model="scope.row.orderNum"
              @input="scope.row.orderNum=$util.isNumber0(scope.row.orderNum)" placeholder="请输入序号" maxlength="3">
            </el-input>
            <span v-if="!showBtn">{{scope.row.orderNum}}</span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column prop="recommend" label="是否设置推荐" min-width="120" align="center">
          <template slot-scope="scope">{{scope.row.recommend==1?"推荐":"不推荐"}}</template>
        </el-table-column> -->
        <!-- <el-table-column prop="type" label="发布到" min-width="120" align="center">
          <template slot-scope="scope">{{scope.row.type==1?"个体端":scope.row.type==2?"团体端":"全部"}}</template>
        </el-table-column> -->
        <el-table-column prop="status" label="当前状态" min-width="100" align="center">
          <template slot-scope="scope">
            <span class="dot" :class="stateClass(scope.row.status)"></span> {{showState(scope.row.status)}}
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="最后操作时间" min-width="200" align="center"></el-table-column>
        <el-table-column prop="updateBy" label="最后操作人" min-width="100" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" width="130" align="center">
          <template slot-scope="scope">
            <div class="flex">
              <div class="text_right">
                <el-button type="text"  size="small" @click="add(scope.row.id)">编辑
                </el-button>
              </div>
              <div>
                <el-button type="text" size="small" class="right_btn"
                  @click="changeState(scope.row,1)">
                  {{scope.row.status==0?"下架":"上架"}} </el-button>
                <el-button type="text" size="small"
                  @click="changeState(scope.row,false)">删除 </el-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <pagination :page="current" :size="size" :total="total" @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"></pagination>
    </el-card>
    <!-- 新增科普音频 -->
    <modal :obj="{
        title: edit ? '编辑' : '新增',
        dialog: addDialog,
        dialogText: 'addDialog',
        form: addForm,
        ref: 'addForm',
        okText: '上线',
        otherText: '保存待投放',
        cancelText: '取消',
        width:'750px'
      }" @submit="addSubmit(true)" @otherSubmit="addSubmit">
      <el-form :model="addForm" label-width="130px" :rules="rules" ref="addForm">
        <el-form-item label="音频分类：" prop="typeId">
          <el-select class="w_100" v-model="addForm.typeId" placeholder="请选择音频分类">
            <el-option v-for="(item, index) in addTypeList" :key="index" :label="item.typeName" :value="item.typeId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="音频标题：" prop="title">
          <el-input v-model="addForm.title" placeholder="请输入音频标题"></el-input>
        </el-form-item>
        <el-form-item label="音频文件：" prop="contentUrl">
          <div class="flex" >
            <el-upload ref="uploader" :action="action3" accept=".mp3" :headers="headers" :show-file-list="false"
              :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <el-button size="small" type="primary" :loading="loading">{{!loading?"本地上传":"上传中"}}</el-button>
            </el-upload>
            <span class="upload_span_text">仅支持mp3格式</span>
          </div>
          <!-- <el-progress v-if="loading" :percentage="percentage" color="#409EFF"></el-progress> -->
          <div>
            <audio class="audio pad_left" v-if="addForm.contentUrl" controls="controls"  name="media">
              <source :src="addForm.contentUrl"  />
            </audio>
          </div>
        </el-form-item>
        <el-form-item label="封面图片：" prop="coverUrl">
          <div class="flex">
            <el-upload :action="action1" accept=".png,.jpg,.jpeg,.bmp,.webp" :headers="headers" :show-file-list="false"
              :on-success="ImgSuccess">
              <el-button size="small" type="primary">本地上传</el-button>
            </el-upload>
            <span class="upload_span_text">建议上传尺寸121pt*92pt，仅支持png/jpg/jpeg/bmp/webp格式</span>
          </div>
          <div class="img_div">
            <img v-if="addForm.coverUrl" :src="addForm.coverUrl" class="img_240" />
          </div>
        </el-form-item>
        <el-form-item label="音频介绍：" prop="summary">
          <el-input type="textarea" placeholder="请输入音频介绍" v-model="addForm.summary" maxlength="120" :rows="4"
            show-word-limit></el-input>
        </el-form-item>
      </el-form>
    </modal>
  </div>
</template>

<script>
  import pagination from "../../components/pagination";
  import modal from "../../components/modal";
  import {
    addPsy,
    updatePsy,
    psyInfo,
    files1,
    files3,
    topicTypes,
    missionExamList,
    bannerState,
    deletePsy,
  } from "../../api/api";
  export default {
    components: {
      pagination,
      modal,
    },
    data() {
      return {
        tableHeight: this.$util.getHeight(310),
        addDialog: false,
        size:10,//每页条数
        current:1,//当前条数
        total: 0,
        searchForm: {
          status: "",
          title: "",
          typeId:"",
          typeTheme: 3,
        },
        addForm: {
          typeId: "",
          title: "",
          author: "",
          summary: "",
          coverUrl: "",
          contentUrl: "",
          status: "",
          typeTheme: 3,
        },
        tableData: [],
        list: [{
          value: "",
          label: "全部",
        }, ],
        showBtn: false,
        rules: {
          typeId: [{
            required: true,
            message: "请选择音频分类"
          }],
          title: [{
            required: true,
            message: "请输入音频标题"
          }],
          contentUrl: [{
            required: true,
            message: "请输入音频文件"
          }],
          coverUrl: [{
            required: true,
            message: "请上传封面图片"
          }],
         
        },
        edit: "",
        typeList: [],
        addTypeList: [],
        startList: [{
            value: null,
            label: "全部",
          }, {
            value: "0",
            label: "上架",
          },{
            value: "1",
            label: "下架",
          },{
            value: "2",
            label: "待上架",
          }],
        action1: files1.url,
        action3: files3.url,
        loading: false, //上传状态
        percentage: 0, //上传进度
        timer: null, //上传进度定时器
        headers: {
          Accept: "application/json, text/plain, */*",
           Authorization:this.$util.getToken("access_token"),
        },
        joinPalyList: [], //相关推荐
      };
    },
    computed: {
    },
    watch: {
      "addDialog"(val, oldVal) {
        if (val === false) {
          this.$refs.uploader.abort()
        }
      },
    },
    created() {
      this.getThemetypeList();
      this.getList();
      // this.getJoinPalyList()
    },
    mounted() {
      window.addEventListener('resize', () => {
        let num = this.showBtn ? 260 : 300
        this.getTableHeight(num)
      })
    },
    methods: {
      getTableHeight(val) {
        this.tableHeight = this.$util.getHeight(val)
      },
      //   自定义表头
      renderHeader() {
        return this.showBtn ? (
          <div>
            <div class="editTd">编辑排序</div>
            <div class="sort_tips">正整数越小越靠前</div>
            <el-button
              type="primary"
              size="small"
              on-click={() => this.editSort()}
            >
              保存
            </el-button>
            <el-button
              size="small"
              on-click={() => ((this.showBtn = false), this.getList())}
            >
              取消
            </el-button>
          </div>
        ) : (
          <div>
            <span class="editTd" on-click={() => (this.showBtn = true,this.getTableHeight(250))}>
              编辑排序
            </span>
          </div>
        );
      },
      // 获取所属类型列表
      getThemetypeList() {
        let typeObj = {
          typeId: null,
          typeName: '全部'
        }
        this.$request(topicTypes,{typeTheme:3}).then((res) => {
          if (res.code === 0) {
           this.typeList = this.$util.superCopy(res.data);
           this.addTypeList = this.$util.superCopy(res.data);
           this.typeList.unshift(typeObj);
          }
        });
      },
      getList(val) {
         if (val) {
          this.current = 1;
        }
        this.getTableHeight(330)
        this.$request(missionExamList(this.current,this.size), this.searchForm).then((res) => {
          if (res.code === 0) {
            let data = res.data;
            this.tableData = data.records;
            this.total = Number(data.total);
          }
        });
      },
      handleSizeChange(val) {
        this.size = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.current = val;
        this.getList();
      },
      handleChange() {
        this.getJoinPalyList();
      },
      // 推荐、上架、删除
      changeState(val, bool) {
        let text;
        if (!bool) {
          text = "确定删除？";
        } else if (bool == 1) {
          text = val.status == 0 ? "确定下架？" : "确定上架？";
        }
        val.bool = bool;
        this.$util.isSwal(text, this.changeStateSubmit, val);
      },
      changeStateSubmit(val) {
        let url = !val.bool ? this.$request(deletePsy,{id:val.id}) : this.$request(bannerState,{id:val.id, status:val.status == 0 ? 1 : 0});
        url.then((res) => {
          if (res.code === 0) {
            this.$util.message("suc", "操作成功");
            this.getList();
          }
        });
      },
      //   编辑排序
      editSort() {
        let ids = [];
        let order = [];
        this.tableData.find((item) => {
          ids.push(item.id);
          order.push(item.orderNum);
        });
        this.$request(batchOrderNews, {
          ids,
          order
        }).then((res) => {
          if (res.code === "200") {
            this.showBtn = false;
            this.$util.message("suc", "操作成功");
            this.getList();
          }
        });
      },
      //   新增科普音频-弹框
      add(val) {
        this.loading = false
        this.addForm = {
          typeId: "",
          title: "",
          author: "",
          summary: "",
          coverUrl: "",
          contentUrl: "",
          status: "",
          typeTheme: 3,
        };
        if (this.$refs.addForm) {
          this.$refs.addForm.resetFields();
        }
        this.edit = val;
        this.addDialog = true;
        if (val) {
          this.getInfo(val);
        }
      },
      // 获取相关推荐
      getJoinPalyList() {
        this.$request(joinPaly).then((res) => {
          if (res.code === "200") {
            this.joinPalyList = res.data;
          }
        });
      },
      // // 改变相关推荐
      // changeNewsJoinEos() {
      //   this.$forceUpdate();
      //   let arr = [];
      //   this.joinPalyList.find((item) => {
      //     this.addForm.arr.find((val) => {
      //       if (item.joinNewsid == val) {
      //         arr.push(item);
      //       }
      //     });
      //   });
      //   this.addForm.newsJoinEos = arr;
      // },
      // 查看详情
       getInfo(val) {
        this.$request(psyInfo,{id:val}).then((res) => {
          if (res.code === 0) {
            this.addForm = res.data;
          }
        });
      },
      //   新增科普音频-上传接口
      addSubmit(val) {
        let url = this.edit ? updatePsy : addPsy;
        this.addForm.status = val ? "0" : "2";
        this.$refs.addForm.validate((result) => {
          if (result) {
            this.$request(url, this.addForm).then((res) => {
              if (res.code === 0) {
                this.addDialog = false;
                this.$util.message("suc", "操作成功");
                this.getList();
              }
            });
          }
        });
      },
      // 上传音频
      handleAvatarSuccess(res, file) {
        if (res.code == 0) {
          this.percentage = 100
          clearInterval(this.timer)
          this.$nextTick(() => {
            this.loading = false
          })
          this.$util.message("suc", "上传成功");
          this.addForm.contentUrl = res.data.url;
          this.$refs.addForm.validate((result) => {
            return true
          })
        } else {
          this.$util.message("err", res.msg);
          this.loading = false

        }
      },
      beforeAvatarUpload(file) {
        this.percentage = 0
        this.addForm.contentUrl = ""
        const isLt2M = file.size / 1024 / 1024 < 500;
        if (!isLt2M) {
          this.$util.message("warning", "上传头像图片大小不能超过 500MB!");
        }
        this.loading = true
        this.timer = setInterval(() => {
          if (this.percentage < 95) {
            this.percentage++
          } else {
            clearInterval(this.timer)
          }
        }, 1000);
        return isLt2M;
      },
      // 上传封面图
      ImgSuccess(res, file) {
        if (res.code == 0) {
          this.$util.message("suc", "上传成功");
          this.addForm.coverUrl = res.data && res.data.url;
          this.$refs.addForm.validate((result) => {
          return true
        })
        } else {
          this.$util.message("err", res.msg);
        }
      },
      showState(val) {
        switch (val) {
          case 0:
            return "上架";
          case 1:
            return "下架";
          case 2:
            return "待上架";
        }
      },
      stateClass(val) {
        switch (val) {
          case 0:
            return "green_dot";
          case 1:
            return "grey_dot";
          case 2:
            return "yellow_dot";
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .video {
    .add {
      padding-top: 3px;
      margin-right: 24px;
    }

    .right_btn {
      margin-left: 8px;
    }

    .img_240 {
      width: 50%;
      min-width: 150px;
      height: 180px;
      border: 1px solid #eeeeee;
    }
        /deep/.el-textarea__inner {
        padding: 5px 50px 5px 15px;
    }
  }
</style>